.flexing{
display: flex;
justify-content: center;
margin-top: 5%;
justify-content: space-around;
flex-wrap:wrap;
align-items: center;




@media (max-width: 1024px) {

  margin-top: 12%;
}
@media(max-width:500px){
  margin-top: 25%;
}


    
.joinrm{
    display: flex;
    flex-direction: column;
    width: 400px;
    margin-right: 4%;
 
    
    margin-left: 4%;
   h1{
        font-size: 32px;
        font-family: "Poppins-SemiBold,sans-serif";
        line-height: 1;
        letter-spacing: 0px;
     
        @media(max-width:600px){
          font-size: 22px;
          max-width: 100%;
          
          
         }
    }
    // p{
      
    
    // }

    .joinrmbricksbtn{
      z-index: 0;
      button{
            background-color: transparent;
            border-radius: 20px;
          color: aliceblue;
          padding: 8px 10px;
          margin-bottom :10px;
          border:1px solid #23294b;
          &:hover{
            background-color: #292f53;
            
          }
          @media(max-width:600px){
            font-size: 16px;

           
                
            }
        
        }
    }

}
}

.imgpyramid{
   img{
    height: min(500px);

 // top: 50px;
 //right: 100px;
  z-index: 0;
 
  @media(max-width:600px){

 
      
  } 

}
 
 
   
}
.ratingpart {
  display: flex;
 justify-content: space-around;
 flex-wrap: wrap;
 margin-top: 1%;
 padding-bottom: 4%;
 z-index:0;
 margin-left: 4%;
 margin-right: 4%;
 row-gap: 20px;

  h3{
    max-width: 650px;
    z-index: 0;
    font-family: "Poppins-SemiBold,sans-serif";
    line-height: 1;
    letter-spacing: 0px;
    padding-bottom:4% ;
    
  }
  @media(max-width:600px){
    font-size: 22px;
    max-width: 100;
     
    h3{
      font-size: 20px;
      margin-left: 4%;
      margin-right: 4%;
      
    }
    }
}



