$color-background: #070e1b;
$color-text: #d4e0f0;
$color-border: #23294b;

$color-dark: #252525;
$color-light: #fafafa;
$color-warn: #ffc52f;
$color-error: #f33f5f;
$color-succe: #15b173;
$color-info: #a7c0ff;

$baseFontSize: 16;
$baseFontLineHeight: 20;

$fontPrimaryLight: "Poppins-Light", sans-serif;
$fontPrimaryExtraLight: "Poppins-ExtraBold", sans-serif;
$fontPrimaryRegular: "Poppins-Regular", sans-serif;
$fontPrimaryMedium: "Poppins-Medium", sans-serif;
$fontPrimarySemiBold: "Poppins-SemiBold", sans-serif;
$fontPrimaryBold: "Poppins-Bold", sans-serif;
$fontPrimaryThin: "Poppins-Black", sans-serif;

$fontBodyLight: "Inter-Light", sans-serif;
$fontBodyRegular: "Inter-Regular", sans-serif;
$fontBodyMedium: "Inter-Medium", sans-serif;
$fontBodyBold: "Inter-Bold", sans-serif;
$fontBodyThin: "Inter-Thin", sans-serif;
$fontBodyBlack: "Inter-Black", sans-serif;
