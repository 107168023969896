/* PRIMARY FONTS */
@font-face {
  font-family: "Poppins-Light";
  src: url("../../assets/fonts/Poppins/Poppins-Light.ttf");
  font-weight: normal;
  font-style: normal;
  line-height: normal;
}

@font-face {
  font-family: "Poppins-ExtraBold";
  src: url("../../assets/fonts/Poppins/Poppins-ExtraBold.ttf");
  font-weight: normal;
  font-style: normal;
  line-height: normal;
}

@font-face {
  font-family: "Poppins-Regular";
  src: url("../../assets/fonts/Poppins/Poppins-Regular.ttf");
  font-weight: normal;
  font-style: normal;
  line-height: normal;
}

@font-face {
  font-family: "Poppins-Medium";
  src: url("../../assets/fonts/Poppins/Poppins-Medium.ttf");
  font-weight: normal;
  font-style: normal;
  line-height: normal;
}

@font-face {
  font-family: "Poppins-SemiBold";
  src: url("../../assets/fonts/Poppins/Poppins-SemiBold.ttf");
  font-weight: normal;
  font-style: normal;
  line-height: normal;
}

@font-face {
  font-family: "Poppins-Bold";
  src: url("../../assets/fonts/Poppins/Poppins-Bold.ttf");
  font-weight: normal;
  font-style: normal;
  line-height: normal;
}

@font-face {
  font-family: "Poppins-Black";
  src: url("../../assets/fonts/Poppins/Poppins-Black.ttf");
  font-weight: normal;
  font-style: normal;
  line-height: normal;
}

/* BODY FONTS */
@font-face {
  font-family: "Inter-Light";
  src: url("../../assets/fonts/Inter/Inter-Light.ttf");
  font-weight: normal;
  font-style: normal;
  line-height: normal;
}

@font-face {
  font-family: "Inter-Regular";
  src: url("../../assets/fonts/Inter/Inter-Regular.ttf");
  font-weight: normal;
  font-style: normal;
  line-height: normal;
}

@font-face {
  font-family: "Inter-Medium";
  src: url("../../assets/fonts/Inter/Inter-Medium.ttf");
  font-weight: normal;
  font-style: normal;
  line-height: normal;
}

@font-face {
  font-family: "Inter-Bold";
  src: url("../../assets/fonts/Inter/Inter-Bold.ttf");
  font-weight: normal;
  font-style: normal;
  line-height: normal;
}

@font-face {
  font-family: "Inter-Thin";
  src: url("../../assets/fonts/Inter/Inter-Thin.ttf");
  font-weight: normal;
  font-style: normal;
  line-height: normal;
}

@font-face {
  font-family: "Inter-Black";
  src: url("../../assets/fonts/Inter/Inter-Black.ttf");
  font-weight: normal;
  font-style: normal;
  line-height: normal;
}
