.section {
  &.h-100 {
    min-height: 100svh;
  }
  position: relative;
  padding: 80px 0;

  @media (max-width: 768px) {
    padding: 40px 0;
  }
}

.section.h-100 {
  min-height: 89svh;
}
.banner-section {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .stars-canvas-animation,
  .planets-canvas-animation,
  .earth-canvas-animation {
    position: absolute;
    top: -56px;
    left: 13px;
    height: 100%;
    width: 100%;
  }
  .stars-canvas-animation {
    // position: fixed;
    z-index: 0;
    pointer-events: none;
  }
  .tag-line-container {
    position: relative;
    text-align: center;
    margin: 0 auto;

    .tag-line {
      letter-spacing: 1px;
      font-size: max(3vw, 22px);
      font-family: $fontPrimaryLight;
      line-height: 1.4;
      color: $color-text;
    }
  }

  @media (max-width: 768px) {
    .stars-canvas-animation,
    .planets-canvas-animation,
    .earth-canvas-animation {
      pointer-events: none;
    }
  }
}

.services-section {
  .services-container {
    .service-card {
      border: 2px solid $color-border;
      border-radius: 10px;
      height: 100%;
      background-color: $color-background;
      overflow: hidden;
      .card-body {
        position: relative;
        z-index: 0;
        color: $color-text;
        padding: 0;
        .service-title {
          padding: 24px;
          display: flex;
          flex-direction: column;
          height: 100%;
          transition: transform 0s linear 0.1s;
          color: $color-text;
          .service-icon {
            .rb-service-icon {
              height: 130px;
              width: 130px;
              object-fit: contain;
              object-position: center;
              border: 1px solid $color-border;
              padding: 6px;
              background-image: linear-gradient(45deg, #0f1724, $color-border);
              border-radius: 10px;
              fill: url(#service-icon-gradient) $color-text;
              stroke: url(#service-icon-gradient) $color-text;
            }
            margin-bottom: 100px;
            flex: 1;
          }
        }
        .service-description {
          position: absolute;
          padding: 16px 24px;
          left: 100%;
          top: 0;
          width: 100%;
          height: 100%;
          transition: transform 0s linear 0.1s;
          p {
            line-height: 1.6;
            font-size: 14px;
            letter-spacing: 0.5px;
            font-family: $fontBodyLight;
            margin-bottom: 0;
          }

          .view-more-link {
            position: absolute;
            bottom: 16px;
            right: 24px;
            a {
              color: $color-text;

              i.bi {
                transition: 0.3s transform ease;
              }

              &:hover {
                i.bi {
                  transform: translateX(6px);
                }
              }
            }
          }
        }
      }

      transition: transform 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s,
        background-image 0.3s linear 0.3s;
    }
    .service-card-wrapper {
      &:hover {
        .service-card {
          transition: transform 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s,
            background-image 0.3s linear 0.3s;
          transform: perspective(500px) rotateY(180deg);
          background-image: linear-gradient(45deg, #0f1724, $color-border);

          .service-title {
            transform: translateX(-100%);
          }
          .service-description {
            transform: translateX(-100%) rotateY(180deg);
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    .services-container {
      .service-card .card-body .service-title {
        padding: 12px;

        .service-icon {
          margin-bottom: 20px;
          .rb-service-icon {
            height: 70px;
            width: 70px;
          }
        }
        h4 {
          font-size: 16px;
          line-height: normal;
          margin-top: -43px;
        }
      }
      .service-card-wrapper {
        .service-card {
          .service-description {
            position: fixed;
            top: 0;
            left: 0;
            height: auto;
            width: 100%;
            transform: none;
            opacity: 0;
            visibility: hidden;
          }
        }
        &:hover {
          .service-card {
            transform: perspective(0) rotateY(0deg);
            .service-title {
              transform: translateX(0%);
            }
          }
        }
      }
    }
  }
}

// @media only screen and (max-width: 1000px) {
//   .tech-stack-section {
//     .tech-stacks {
//       .tech-stack-thumb {
//         position: relative;
//         margin-top: 80px;
//         .animation-logo {
//           position: absolute;
//           top: 50%;
//           left: 50%;
//           transform: translateX(-50%);
//           height: min(46px, 8vh);
//           max-height: 100%;
//           width: auto;
//         }
//       }
//       .tech-stack-description p {
//         line-height: 1.8;
//         // font-size: 14px;
//         letter-spacing: 0.5px;
//         font-family: $fontBodyLight;
//         margin-bottom: 0;
//         text-align: start;
//       }

//       .tech-perspective {
//         .path-wrapper {
//           position: relative;
//           .animation-logo {
//             position: absolute;
//             top: 50%;
//             left: 47%;
//             transform: translate(-50%, -50%);
//           }
//           .circle-paths {
//             max-width: 79%;
//             height: 52%;
//             margin: 0px 24px auto;
//             display: flex;
//             justify-content: center;
//             flex-wrap: wrap;
//           }
//         }
//         .animateTechs {
//           position: absolute;
//           pointer-events: none;
//           top: 0;
//           left: 0;
//           width: 89%;
//           height: 100%;
//           max-width: 500%;
//           margin: -4px 4px auto;
//           // justify-self: anchor-center;
//           animation: TechCircleAnimation 100s linear infinite;

//           .tech-svgs {
//             display: flex;
//             align-items: center;
//             flex-wrap: wrap;
//             justify-content: space-around;
//             .svg {
//               position: absolute;
//               top: 10%;
//               left: 10%;
//               // transform: translate(-50%, -50%);
//               width: 8%;
//               height: 8%;
//               object-fit: contain;
//               margin: 0 40px;
//               animation: TechCircleAnimation 100s linear infinite reverse;

//               &:nth-child(1) {
//                 top: 20%;
//                 left: -4%;
//               }
//               &:nth-child(2) {
//                 top: 1%;
//                 left: 20%;
//               }
//               &:nth-child(3) {
//                 top: 83%;
//                 left: 61%;
//               }
//               &:nth-child(4) {
//                 top: 51%;
//                 left: 53%;
//               }
//               &:nth-child(5) {
//                 top: 70%;
//                 left: 29%;
//               }
//               &:nth-child(6) {
//                 top: 96%;
//                 left: 31%;
//               }
//               &:nth-child(7) {
//                 top: 80%;
//                 left: 1%;
//               }
//               &:nth-child(8) {
//                 top: 0%;
//                 left: 50%;
//               }
//               &:nth-child(9) {
//                 top: 52%;
//                 left: 8%;
//               }
//               &:nth-child(10) {
//                 top: 47%;
//                 left: -12%;
//               }
//               &:nth-child(11) {
//                 top: 79%;
//                 left: 44%;
//               }
//               &:nth-child(12) {
//                 top: 22%;
//                 left: 40%;
//               }
//               &:nth-child(13) {
//                 top: 72%;
//                 left: 8%;
//               }
//               &:nth-child(14) {
//                 top: 49%;
//                 left: 65%;
//               }
//               &:nth-child(15) {
//                 top: 13%;
//                 left: 48%;
//               }
//               &:nth-child(16) {
//                 top: 31%;
//                 left: 2%;
//               }
//               &:nth-child(17) {
//                 top: 26%;
//                 left: 72%;
//               }
//               &:nth-child(18) {
//                 top: 58%;
//                 left: 74%;
//               }
//               &:nth-child(19) {
//                 top: 29%;
//                 left: 16%;
//               }
//             }
//           }
//         }
//       }
//     }
//   }
// }

/* For tablets (portrait & landscape) */
@media (min-width: 600px) and (max-width: 1024px) and (max-width: 1440px) and (max-width: 1920px) {
  .tech-stack-section {
    .tech-stacks {
      .tech-stack-thumb {
        position: relative;
        margin-top: 80px;
        .animation-logo {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translateX(-50%);
          height: min(65px, 8vh);
          max-height: 100%;
          width: auto;
        }
      }
      .tech-stack-description p {
        line-height: 1.8;
        // font-size: 14px;
        letter-spacing: 0.5px;
        font-family: $fontBodyLight;
        margin-bottom: 0;
        text-align: start;
      }

      .tech-perspective {
        .path-wrapper {
          position: relative;
          .animation-logo {
            position: absolute;
            top: 50%;
            left: -33%;
            transform: translate(-50%, -50%);
          }
          .circle-paths {
            max-width: 259%;
            height: 52%;
            margin: 88px -165px auto;
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
          }
        }
        .animateTechs {
          position: absolute;
          pointer-events: none;
          top: 0;
          left: 0;
          width: 263%;
          height: 100%;
          max-width: 500%;
          margin: -4px -159px auto;
          animation: TechCircleAnimation 100slinear infinite;

          .tech-svgs {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            justify-content: space-around;
            .svg {
              position: absolute;
              top: 10%;
              left: 10%;
              // transform: translate(-50%, -50%);
              width: 8%;
              height: 8%;
              object-fit: contain;
              margin: 0 40px;
              animation: TechCircleAnimation 100s linear infinite reverse;

              &:nth-child(1) {
                top: 32%;
                left: -13%;
              }
              &:nth-child(2) {
                top: 3%;
                left: 7%;
              }
              &:nth-child(3) {
                top: 90%;
                left: 56%;
              }
              &:nth-child(4) {
                top: 46%;
                left: 54%;
              }
              &:nth-child(5) {
                top: 70%;
                left: 38%;
              }
              &:nth-child(6) {
                top: 96%;
                left: 25%;
              }
              &:nth-child(7) {
                top: 73%;
                left: -11%;
              }
              &:nth-child(8) {
                top: -3%;
                left: 40%;
              }
              &:nth-child(9) {
                top: 62%;
                left: 14%;
              }
              &:nth-child(10) {
                top: 39%;
                left: 80%;
              }
              &:nth-child(11) {
                top: 78%;
                left: 47%;
              }
              &:nth-child(12) {
                top: 23%;
                left: 41%;
              }
              &:nth-child(13) {
                top: 79%;
                left: 15%;
              }
              &:nth-child(14) {
                top: 34%;
                left: 65%;
              }
              &:nth-child(15) {
                top: 11%;
                left: 34%;
              }
              &:nth-child(16) {
                top: 42%;
                left: -4%;
              }
              &:nth-child(17) {
                top: 13%;
                left: 69%;
              }
              &:nth-child(18) {
                top: 67%;
                left: 73%;
              }
              &:nth-child(19) {
                top: 32%;
                left: 18%;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1024px) {
  .tech-stack-section {
    .tech-stacks {
      .tech-stack-thumb {
        position: relative;
        margin-top: 80px;

        .animation-logo {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translateX(-50%);
          height: min(45px, 8vh);
          max-height: 100%;
          width: auto;
        }
      }
      .tech-stack-description p {
        line-height: 1.8;
        // font-size: 14px;
        letter-spacing: 0.5px;
        font-family: $fontBodyLight;
        margin-bottom: 0;
        text-align: start;
      }

      .tech-perspective {
        .path-wrapper {
          position: relative;
          .animation-logo {
            position: absolute;
            top: 50%;
            left: 3%;
            transform: translate(-50%, -50%);
          }
          .circle-paths {
            max-width: 259%;
            height: 52%;
            margin: -100px -225px auto;
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
          }
        }
        .animateTechs {
          position: absolute;
          pointer-events: none;
          top: 0;
          left: 0;
          width: 263%;
          height: 100%;
          max-width: 500%;
          margin: -4px -236px auto;
          animation: TechCircleAnimation 100slinear infinite;

          .tech-svgs {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            justify-content: space-around;
            .svg {
              position: absolute;
              top: 10%;
              left: 10%;
              // transform: translate(-50%, -50%);
              width: 8%;
              height: 8%;
              object-fit: contain;
              margin: 0 40px;
              animation: TechCircleAnimation 100s linear infinite reverse;

              &:nth-child(1) {
                top: 22%;
                left: -8%;
              }
              &:nth-child(2) {
                top: 1%;
                left: 14%;
              }
              &:nth-child(3) {
                top: 88%;
                left: 60%;
              }
              &:nth-child(4) {
                top: 46%;
                left: 59%;
              }
              &:nth-child(5) {
                top: 70%;
                left: 38%;
              }
              &:nth-child(6) {
                top: 96%;
                left: 25%;
              }
              &:nth-child(7) {
                top: 61%;
                left: -9%;
              }
              &:nth-child(8) {
                top: -3%;
                left: 40%;
              }
              &:nth-child(9) {
                top: 56%;
                left: 14%;
              }
              &:nth-child(10) {
                top: 35%;
                left: 85%;
              }
              &:nth-child(11) {
                top: 70%;
                left: 63%;
              }
              &:nth-child(12) {
                top: 24%;
                left: 41%;
              }
              &:nth-child(13) {
                top: 81%;
                left: 20%;
              }
              &:nth-child(14) {
                top: 35%;
                left: 71%;
              }
              &:nth-child(15) {
                top: 13%;
                left: 48%;
              }
              &:nth-child(16) {
                top: 33%;
                left: 2%;
              }
              &:nth-child(17) {
                top: 6%;
                left: 66%;
              }
              &:nth-child(18) {
                top: 67%;
                left: 83%;
              }
              &:nth-child(19) {
                top: 32%;
                left: 18%;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .tech-stack-section {
    .tech-stacks {
      .tech-stack-thumb {
        position: relative;
        margin-top: 80px;
        .animation-logo {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translateX(-50%);
          height: min(65px, 8vh);
          max-height: 100%;
          width: auto;
        }
      }
      .tech-stack-description p {
        line-height: 1.8;
        // font-size: 14px;
        letter-spacing: 0.5px;
        font-family: $fontBodyLight;
        margin-bottom: 0;
        text-align: start;
      }

      .tech-perspective {
        .path-wrapper {
          position: relative;
          .animation-logo {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
          .circle-paths {
            max-width: 102%;
            height: 106%;
            margin: 88px -3px auto;
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
          }
        }
        .animateTechs {
          position: absolute;
          pointer-events: none;
          top: 0;
          left: 0;
          width: 107%;
          height: 100%;
          max-width: 500%;
          margin: -4px -12px auto;
          animation: TechCircleAnimation 100slinear infinite;

          .tech-svgs {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            justify-content: space-around;
            .svg {
              position: absolute;
              top: 10%;
              left: 10%;
              // transform: translate(-50%, -50%);
              width: 8%;
              height: 8%;
              object-fit: contain;
              margin: 0 40px;
              animation: TechCircleAnimation 100s linear infinite reverse;

              &:nth-child(1) {
                top: 32%;
                left: -10%;
              }
              &:nth-child(2) {
                top: 5%;
                left: 7%;
              }
              &:nth-child(3) {
                top: 92%;
                left: 56%;
              }
              &:nth-child(4) {
                top: 46%;
                left: 57%;
              }
              &:nth-child(5) {
                top: 71%;
                left: 38%;
              }
              &:nth-child(6) {
                top: 93%;
                left: 17%;
              }
              &:nth-child(7) {
                top: 71%;
                left: -8%;
              }
              &:nth-child(8) {
                top: -3%;
                left: 40%;
              }
              &:nth-child(9) {
                top: 61%;
                left: 15%;
              }
              &:nth-child(10) {
                top: 39%;
                left: 81%;
              }
              &:nth-child(11) {
                top: 74%;
                left: 61%;
              }
              &:nth-child(12) {
                top: 23%;
                left: 41%;
              }
              &:nth-child(13) {
                top: 79%;
                left: 15%;
              }
              &:nth-child(14) {
                top: 34%;
                left: 68%;
              }
              &:nth-child(15) {
                top: 11%;
                left: 34%;
              }
              &:nth-child(16) {
                top: 42%;
                left: -1%;
              }
              &:nth-child(17) {
                top: 13%;
                left: 69%;
              }
              &:nth-child(18) {
                top: 67%;
                left: 77%;
              }
              &:nth-child(19) {
                top: 32%;
                left: 18%;
              }
            }
          }
        }
      }
    }
  }
}

.statistics-section {
  // [class*="col-"]:not(:last-child) {
  .statistics {
    position: relative;
    // background-color: lighten($color-background, 2%);
    background-image: linear-gradient(-45deg, #0f1724, $color-border);
    padding: 16px 24px;
    border: 2px solid $color-border;
    border-radius: 10px;
    text-align: center;
    &::before {
      content: "";
      height: 2px;
      width: 100px;
      background-color: $color-text;
      display: block;
      position: absolute;
      top: -2px;
      left: 50%;
      transform: translateX(-50%);
      box-shadow: 0px 0px 6px 0px rgba($color-text, 0.8),
        0px 0px 6px 0px rgba($color-text, 0.3);
      transition: 0.1s ease;
    }

    &:hover {
      &::before {
        box-shadow: 0px 0px 6px 0px rgba($color-text, 0.8),
          0px 10px 26px 4px rgba($color-text, 0.3);
      }
    }
  }
  // }
}

.clients-section {
  display: block;
  .clients-wrapper {
    .client-brand-img {
      background-image: linear-gradient(
        45deg,
        darken($color-border, 13%),
        lighten($color-background, 5%),
        darken($color-border, 13%)
      );
      padding-top: 30px;
      padding-bottom: 30px;
      .slick-track {
        align-items: center;
        .slick-slide {
          display: grid;
          padding: 0 20px;
          outline: 0;
          .client-logo {
            height: 60px;
            width: 100%;
            object-fit: contain;
            object-position: center;
            transition: 0.4s;
            display: block;
            margin: 0 auto;
            filter: grayscale(1);
            &:focus,
            &:focus-visible {
              outline: 0;
            }
            &:hover {
              filter: grayscale(0);
            }
          }
        }
      }
    }
  }
  @media (max-width: 700px) {
    .clients-wrapper {
      display: inline;
      .client-brand-img {
        padding-top: 15px;
        padding-bottom: 15px;
        .slick-track .slick-slide .client-logo {
          height: 40px;
        }
      }
    }
  }
}

.locations {
  .map-container {
    position: relative;
    .pin-container {
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      .map-pin {
        height: 4%;
        // width: 20px;
        position: absolute;

        &.bounce {
          animation: bounce 1.5s ease infinite;
        }

        &.ahmedabad {
          left: 65.9%;
          top: 57.3%;
        }
        &.bangalore {
          left: 67.5%;
          top: 61.5%;
        }
        &.canada {
          left: 23.7%;
          top: 46.5%;
        }
      }
    }
    .ag-canvas {
      .world-map {
        height: 50%;
        fill: $color-border;

        #CA,
        #IN {
          fill: lighten($color-border, 10%);
        }
      }
    }
  }
  .address-container {
    .address {
      padding: 12px;
      border: 2px solid $color-border;
      margin-bottom: 24px;
      border-radius: 8px;
      a {
        color: $color-text;
        &:hover {
          text-decoration: underline;
        }
      }
      .location-icon {
        padding: 4px;
        margin-right: 15px;
        .bi {
          font-size: 18px;
        }
      }

      &:hover {
        @extend .border-animation-box;
      }
    }
  }
}

/* Animatad Border */
@property --border-angle {
  syntax: "<angle>";
  inherits: true;
  initial-value: 0turn;
}

.border-animation-box {
  --border-angle: 0turn; // For animation.
  --main-bg: conic-gradient(
    from var(--border-angle),
    #070e1b,
    #070e1b 5%,
    #070e1b 60%,
    #070e1b 95%
  );
  border: solid 2px transparent;
  // border-radius: 2em;
  --gradient-border: conic-gradient(
    from var(--border-angle),
    transparent 25%,
    #1b3246,
    #5265bd 99%,
    transparent
  );

  background: 
    // padding-box clip this background in to the overall element except the border.
    var(--main-bg) padding-box,
    // border-box extends this background to the border space
    var(--gradient-border) border-box,
    // Duplicate main background to fill in behind the gradient border. You can remove this if you want the border to extend "outside" the box background.
    var(--main-bg) border-box;

  background-position: center center;

  animation: bg-spin 3s linear infinite;
}

@keyframes bg-spin {
  to {
    --border-angle: 1turn;
  }
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-16px) scale(1.5);
  }
  60% {
    transform: translateY(-8px);
  }
}

@keyframes moveUp {
  0% {
    transform: translate3d(0px, 0px, 0px);
    animation-timing-function: ease-in;
  }

  50% {
    transform: translate3d(0px, 10px, 0px);
    animation-timing-function: ease-out;
  }

  100% {
    transform: translate3d(0px, 0px, 0px);
    animation-timing-function: ease-in;
  }
}

.moveUp {
  animation: moveUp 2s infinite;
}

@keyframes moveDown {
  0% {
    transform: translate3d(0px, 0px, 0px);
    animation-timing-function: ease-in;
  }

  50% {
    transform: translate3d(0px, -10px, 0px);
    animation-timing-function: ease-out;
  }

  100% {
    transform: translate3d(0px, 0px, 0px);
    animation-timing-function: ease-in;
  }
}

.moveDown {
  animation: moveDown 2s infinite;
}

.wrapper {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
}

ul#scene {
  list-style-type: none;
}

.layer {
  display: block;
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  position: absolute;
}

li.layer a {
  color: white;
  text-decoration: none;
  text-shadow: 3px 3px 3px #ccc;
  display: block;
}
// li.layer:nth-child(1) {
// } /*1 item*/

/*-----Logotype----------------*/
li.layer:nth-child(2) a {
  font-size: 3.5vw;
  color: white;
  text-shadow: 2px 2px 2px #ccc;
  padding: 7px;
  border: 2px solid white;
  border-radius: 5px;
  box-shadow: 2px 2px 2px #ccc;
  position: absolute;
  top: 40vh;
  left: 40vw;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  -ms-transition: all 1s ease;
  transition: all 1s ease;
}

li.layer:nth-child(2) a:hover {
  font-size: 4.5vw;
}

/*-----P----------------------*/
li.layer:nth-child(3) a {
  font-size: 5vw;
  position: absolute;
  top: 15vh;
  left: 15vw;
}

/*-----O---------------------*/
li.layer:nth-child(4) a {
  font-size: 8vw;
  position: absolute;
  top: 40vh;
  left: 22vw;
}

/*-----R---------------------*/
li.layer:nth-child(5) a {
  font-size: 6vw;
  position: absolute;
  top: 70vh;
  left: 35vw;
}

/*-----T---------------------*/
li.layer:nth-child(6) a {
  font-size: 3.5vw;
  position: absolute;
  top: 60vh;
  left: 47vw;
}

/*-----F---------------------*/
li.layer:nth-child(7) a {
  font-size: 3.5vw;
  position: absolute;
  top: 18vh;
  left: 40vw;
}

/*-----O---------------------*/
li.layer:nth-child(8) a {
  font-size: 3.5vw;
  position: absolute;
  top: 18vh;
  left: 55vw;
}

/*-----L---------------------*/
li.layer:nth-child(9) a {
  font-size: 5vw;
  position: absolute;
  top: 15vh;
  right: 20vw;
}

/*-----I---------------------*/
li.layer:nth-child(10) a {
  font-size: 6vw;
  position: absolute;
  top: 35vh;
  right: 30vw;
}
/*-----O---------------------*/
li.layer:nth-child(11) a {
  font-size: 6.5vw;
  position: absolute;
  top: 65vh;
  right: 37vw;
}

// @keyframes TechCircleAnimation {
//   0% {
//     transform: rotate(0deg);
//   }
//   100% {
//     transform: rotate(360deg);
//   }
// }

@media (min-width: 768px) {
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
    flex: 0 0 auto;
    width: 100%;
    display: inline-flex;
    gap: 28%;
    height: 100%;
  }
}
.section {
  position: relative;
  padding: 6px 0;
}
.tech-stack {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 10%;
  flex-wrap: wrap;
  @media (max-width: 600px) {
    margin-left: 2%;
    margin-right: 2%;
  }
  .tech-stack-container {
    display: flex;
    flex-direction: column;
    max-width: 700px;
    .section-heading {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
    }

    // .tech-svgs{
    //   display: grid;
    //   grid-template-columns: 1fr 1fr 1fr 1fr ;

    //  column-gap: 0px;

    //  .icon{
    //   height: 150px;
    //   display: flex;
    //   align-items: center;
    // // &:hover{

    // // }
    //  }

    // }
  }
}

.tech-svgs {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  align-items: center;
  column-gap: 8px;
  display: grid;
  gap: 30px;
  margin-top: 2%;
  @media (max-width: 600px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    column-gap: 0px;
  }

  .icon {
    height: 100px;
    display: flex;
    align-items: center;
    &:hover {
      opacity: 0.6;
    }

    @media (max-width: 600px) {
      height: 40px;
      width: 40px;
      gap: 0px;
    }
    // &:hover{

    // }
  }
}

.btns-for-meetingandcs {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1%;
  margin-top: 20px;
  #button-for-casestudies {
    background-color: #10142b;
    opacity: 1;
    border: 2px solid #23294b;
    border-radius: 25px;
    cursor: pointer;

    padding: 10px 20px;
    outline: none;
    color: aliceblue;
    font-family: "poppins";
    &:hover {
      background-color: #23294b;
    }
  }
}
