.dialogue-wrapper {
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 99;

  overflow: auto;
  @include customScrollbar();
  .dialogue-overlay {
    position: fixed;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    background-color: rgba(#000, 0.6);
    backdrop-filter: blur(4px);
    opacity: 0;
    transition: 0.15s ease-out;
    &.fadeIn {
      opacity: 1;
    }
  }

  .dialogue {
    position: relative;
    max-width: min(600px, 95%);
    margin: 100px auto;
    width: 100%;
    min-height: 100px;
    border: 1px solid $color-border;
    background-image: linear-gradient(45deg, #0f1724, $color-border);
    border-radius: 10px;
    box-shadow: 0px 4px 20px -2px rgba($color-dark, 0.3);
    transform: scale(0.6);
    opacity: 0;
    transition: 0.15s ease-out;
    &.fadeIn {
      transform: scale(1);
      opacity: 1;
    }

    .dialogue-header {
      padding: 24px 24px 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .btn-close {
        background: none;
        padding: 0;
        height: 24px;
        width: 24px;
        .bi {
          color: $color-text;
          font-size: 22px;
          height: 22px;
          width: 22px;
          line-height: 0;
        }
      }
    }

    .dialogue-body {
      padding: 12px 24px;
      .form-control,
      .form-control-lg {
        font-size: 16px;
        height: 48px;
        background-color: rgba($color-background, 0.2);
        color: $color-text;
        border-color: lighten($color-border, 15%);

        &.textarea {
          min-height: 140px;
        }
      }
    }

    .dialogue-footer {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      column-gap: 16px;
      padding: 12px 24px 24px;
    }
  }

  @media (max-width: 576px) {
    .dialogue {
      .dialogue-header {
        padding: 16px 16px 16px;
      }
      .dialogue-footer {
        padding: 12px 16px 16px;
      }
      .dialogue-body {
        padding: 12px 16px;
      }
    }
  }
}

.btn {
  padding: 8px 22px;
  color: $color-text;
  position: relative;
  border-radius: 40px;
  border: 2px solid $color-border;

  &.btn-primary:focus,
  &.btn-primary:hover,
  &.btn-primary {
    background-color: lighten($color-background, 5%);
    border-color: #0f1724;
  }
  &.btn-primary:hover {
    background-color: $color-background;
  }
  &.btn-outline-secondary:hover {
    background-color: rgba(#0f1724, 0.4);
    border-color: rgba(#0f1724, 0.4);
  }
}
