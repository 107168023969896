.privacy-policy-container {
  max-width: 800px;
  margin: auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  color: #fcf9f9;
  margin-top: 6%;
  border-bottom: 3px solid rgba(3, 23, 58, 0.907);

  h1,
  h2 {
    color: #ffffff;
    font-size: 2rem;
    border-bottom: 3px solid rgba(2, 36, 94, 0.818);
  }

  ul {
    margin-left: 20px;
    li {
      margin-bottom: 10px;
    }
  }

  a {
    color: #ffffff;
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }
}

@media (min-width: 320px) and (max-width: 450px) {
  .privacy-policy-container {
    max-width: 800px;
    margin: auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    color: #fcf9f9;
    margin-top: 18%;
    border-bottom: 3px solid rgba(3, 23, 58, 0.907);

    h1,
    h2 {
      color: #ffffff;
      font-size: 98%;
      border-bottom: 3px solid rgba(2, 36, 94, 0.818);
    }

    ul {
      margin-left: 20px;
      li {
        margin-bottom: 10px;
      }
    }

    a {
      color: #ffffff;
      text-decoration: none;
    }

    a:hover {
      text-decoration: underline;
    }
  }
}
