@use "sass:math";

@function rem($value) {
  $remValue: calc($value / $baseFontSize) + rem;
  @return $remValue;
}

@mixin transform($property) {
  -webkit-transform: $property;
  -ms-transform: $property;
  transform: $property;
}

@mixin transition($transition, $transitionFunction: ease-in-out) {
  transition: $transition;
  -webkit-transition: $transition;
  -moz-transition: $transition;
  -ms-transition: $transition;
  -o-transition: $transition;
  transition-timing-function: $transitionFunction;
  -o-transition-timing-function: $transitionFunction;
  -moz-transition-timing-function: $transitionFunction;
  -webkit-transition-timing-function: $transitionFunction;
}

@mixin box-shadow($val) {
  -webkit-box-shadow: $val;
  -moz-box-shadow: $val;
  box-shadow: $val;
}

@mixin appereance {
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  -ms-appearance: none;
  appearance: none;
}

@mixin placeholder-color($color) {
  &::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    color: $color;
  }

  &:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: $color;
    opacity: 1;
  }

  &::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: $color;
    opacity: 1;
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: $color;
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: $color;
  }

  &::placeholder {
    /* Most modern browsers support this now. */
    color: $color;
  }
}

@mixin customScrollbar() {
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $color-background;
    border-radius: 10px;
    scrollbar-width: thin;
    outline: 0;
  }
}

@mixin textGradient($dir, $color1, $color2) {
  background: -webkit-linear-gradient($dir, $color1, $color2);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@mixin gradientBorder(
  $dir,
  $color1,
  $color2,
  $radius: 0px,
  $border-width: 2px,
  $backgroundColor: $color-background
) {
  position: relative;
  border: 0px;
  border-radius: $radius;
  overflow: hidden;
  span {
    position: relative;
    z-index: 1;
  }
  &::after {
    position: absolute;
    content: "";
    top: $border-width;
    left: $border-width;
    width: calc(100% - $border-width * 2);
    height: calc(100% - $border-width * 2);
    border-radius: calc($radius - $border-width);
    background-color: $backgroundColor;
  }
  background-image: linear-gradient($dir, $color1, $color2);
}
