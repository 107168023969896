.formpart {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 5%;
  padding-top: 0%;
  margin-left: 4%;
  margin-right: 4%;
  
  .img-part{
    img{
      
      @media (max-width:600px){
        width: fit-content;
        height: fit-content;
      }
    }
  }

  form {
    display: flex;
    background-color: transparent;
    
  
    border-radius: 20px;
    padding: 10px 0px;
    backdrop-filter: blur(60px);
    flex-direction: column;
    justify-content: sp;
    align-items: center;
    width: 31%;
    @media (max-width: 900px) {
      min-width: 100%;
    }

    &:hover {
      animation: shake 0.8s;
      animation-iteration-count: 1;
    }
    // @keyframes shake {
    //   0% {
    //     transform: translate(1px, 1px) rotate(0deg);
    //   }

    //   20% {
    //     transform: translate(-3px, 0px) rotate(1deg);
    //   }

    //   40% {
    //     transform: translate(1px, -1px) rotate(-1deg);
    //   }

    //   60% {
    //     transform: translate(-3px, 1px) rotate(0deg);
    //   }

    //   80% {
    //     transform: translate(-1px, -1px) rotate(1deg);
    //   }

    //   100% {
    //     transform: translate(1px, -2px) rotate(-1deg);
    //   }
    // }

    input {
      background-color: transparent;
      border: none;
      outline: none;
      border-bottom: 2px solid #23294b;
      width: 28vw;
      color: aliceblue;
      padding: 0px;
      @media (max-width: 900px) {
        width: 70vw;
      }
    }
  }
  #submit {
    background-color: transparent;
    border-radius: 20px;
    width: 80px;
    color: aliceblue;
    padding: 5px 0px;
    margin-bottom: 10px;
    border: 1px solid #23294b;
    &:hover {
      background-color: #292f53;
    }
    @media (max-width: 600px) {
      font-size: 10px;
    }
  }
}
.inputpart {
  #rederror {
    color: red;
    display: flex;
    flex-direction: column;
    font-size: 12px;
    justify-content: center;
    align-items: center;
    filter: drop-shadow(1px 1px 4px red);
  }
}

.inputpart {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 10px;
  input[type="file"] {
    display: none;
  }

  label {
    padding: 10px 20px;
    background-color: #021529;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 81px;

    &:hover {
      background-color: #021a33;
    }

    &:active {
      background-color: #011a32;
    }
  }

  #rederror {
    color: red;
    margin-top: 5px;
  }
}

.inputpart {
  margin-bottom: 15px;
}

#submit {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 10px 20px;
  text-align: center;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 8px;
}

@media only screen and (max-width: 600px) {
  .inputpart {
    margin-bottom: 10px;
  }

  #submit {
    width: 100%;
  }

  .inputpart input,
  .inputpart label {
    width: 100%;
    box-sizing: border-box;
  }

  .inputpart {
    display: flex;
    flex-direction: column;
  }
}

.inputpart label {
  @media (max-width: 700px) {
    font-size: 10px;
  }
}

@media screen and (max-width: 720px) {
  .formpart {
    h4 {
      margin-top: -102px;
      padding: 104px 0;
      display: flex;
      justify-content: center;
      margin: -88px 0;
    }
    .inputpart label {
      font-size: 10px;
      margin-top: 13px;
    }
    .form {
      min-width: 86%;
    }
  }
}
 /////////////////////////////////////////////////////////////////////////////////////////////////////
 /// 
 /// 
 /// 
 /// 
 /// 
 /// 
 /// // SCSS for the drag and drop file input form

// .formpart {
//   max-width: 600px;
//   margin: 0 auto;
//   padding: 20px;
//   background-color: #f9f9f9;
//   border-radius: 10px;
//   box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
// }

// .img-part {
//   text-align: center;
//   margin-bottom: 20px;

//   img {
//     max-width: 100%;
//     height: auto;
//   }
// }

// .inputpart {
//   margin-bottom: 15px;

//   input[type="text"],
//   input[type="email"] {
//     width: 100%;
//     padding: 10px;
//     border: 1px solid #ccc;
//     border-radius: 6px;
//     font-size: 14px;
//     box-sizing: border-box;
//   }

//   input:focus {
//     border-color: #4a90e2;
//     outline: none;
//   }
// }

// .error-message {
//   color: #d9534f;
//   font-size: 12px;
//   margin-top: 4px;
// }

.drag-drop-area {
  margin-top: 4%;
  border: 2px dashed #23294b;

  border-radius: 10px;
  padding: 20px 20px;
  text-align: center;
  cursor: pointer;
  margin-bottom: 15px;
  background-color: transparent;
  transition: all 0.3s;
}
//   p {
//     margin: 0;
//     color: #777;
//     font-size: 14px;
//   }

//   &:hover {
//     background-color: #f0f8ff;
//     border-color: #4a90e2;
//   }
// }

.hidden-file-input {
  display: none;
}

.submit-btn {
  width: 70%;
  padding: 12px 0;
  background-color: #021529;
  color: #fff;
  border: none;
  border-radius: 6px;
  font-size: 16px;
  cursor: pointer;

  transition: background-color 0.2s;

  &:hover {
    background-color: #292f53;
  }
}