.tabs {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  gap: 30px;

  margin-top: 200px !important;
  background-color: rgba(3, 3, 52, 0.607);
  .tab {
    outline: none;
    padding: 20px;
    padding: 10px;
    &:hover {
      background-color: rgb(2, 2, 54);
    }
  }
}

.tabcontent1 {
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  margin-top: 20px;

  p {
   
  font-size: 16px;
  font-weight: 100;
  font-family: "Inter-Light", sans-serif;
    @media (max-width: 600px) {
      font-size: 14px;
      margin-left: 5%;
    }
  }
}
.lifeatrm {
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  margin-top: 20px;

  //  .imagepart{
  //     img{
  //         height: 500px;

  //         border-radius: 8px;
  //         box-shadow: 1px 1px 20px rgba(72, 55, 55, 0.521);

  //     }
  //  }
  .lifedesc {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    max-width: 800px;
    justify-content: center;
  }
}
// // .hiring-part{
// //     display: flex;
// //   flex-wrap: wrap;
// //   margin-top: 10px;
// //   background-color: rgba(1, 1, 34, 0.684);
// // position: relative;
// // padding: 40px 0px;
// // margin-left: 4rem;
// // .hiring-process{
// //         display: flex;

// //        align-items: center;
// //        margin-left: 2rem;
// //     flex-wrap: wrap;
// //        gap: 10px;
// //        margin-top: 10px !important;
// //      max-height: 600px;
// //     .hiring-step{
// //         display: flex;
// //         flex-wrap: wrap;
// //         flex-direction: column;
// //         border:2px solid #23294b;
// //         height: auto;
// //         justify-content: center;
// //         align-items: center;

// //         max-width: 270px;
// //         height: 300px;
// //         padding: 30px 10px;
// //         border-radius: 20px;
// //     h5{
// //         font-size: 18px;
// //     }
// //     p{
// //         font-size: 14px;
// //     }
// //    }
// // }
// }

// @media screen and (max-width: 600px) {
//    .hiring-step{
//     max-width: 200px;
//     max-height: 150px;
//    overflow-y: scroll;
//   display: none;

//    }

// h5{
//     font-size: 14px;
// }
// p{
//     font-size: 12px;
// }

//   }

.benefit {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  margin-top: 3%;
  margin-left: 7.7%;

  .benefit-part {
    display: flex;
    flex-direction: column;
    max-width: 300px;
    border: 1px solid #23294b;
    padding: 20px;
    gap: 10px;
    border-radius: 20px;
    .benefit-image {
      display: flex;
      justify-content: center;

      h1 {
        font-size: 34px;
        color: #9a48e2cb;
      }
    }
    h5 {
      font-family: "Poppins-SemiBold,sans-serif";
      border: none;
      width: 100%;
      font-size: 25px;
    }
    p {
      font-family: "Inter-Light,sans-serif";
    }
  }
}

@media (max-width: 600px) {
  .benefit-part {
    height: max-content;
    width: fit-content;
  }
}

.hiring-part {
  margin-left: 7.7%;
  margin-right: 7.7%;
  margin-top: 2%;

  h3 {
    font-size: 24px;
    font-family: "Poppins-SemiBold,sans-serif";
    border: none;
  }
  p {
    font-size: 18px;
    font-family: "Inter-Light,sans-serif";
  }
  .tabmar {
    margin-top: 6%;
  }

  .hiring-process {
    display: flex;
   flex-wrap: wrap;
    justify-content:flex-start;
    

   

    gap: 15px;

    .hiring-step {
      padding: 1% 1%;
      border: 2px solid #23294b;
      border-radius: 10px;

      h5 {
        border: none;
        font-size: 18px;
        font-family: "Poppins-SemiBold,sans-serif";
      }
      p {
        font-size: 14px;
        max-width: 230px;
        font-family: "Inter-Light,sans-serif";
      }
    }
  }
}
.tabcontent1 {
  margin-left: 4%;
  margin-right: 7.7%;
  margin-top: 3%;

}

.tab {
  display: flex;
  font-size: 27px;
  list-style: none;
  margin-left: -2%;
 
  justify-content: center;
  margin-top: 63px;

  @media (max-width: 600px) {
    font-size: 14px;
    margin-left: -2%;
  }

  .tabcolor {
    outline: none;
  color: aliceblue;
    background-color: #10142b;
    padding: 20px 65px;
    @media (max-width:600px){
        padding: 15px 35px;
    }
  
  

    &:hover {
      background-color: #23294b;
    }
   
    
  }
}
