// .sectionpart2 {
//   display: flex;
//   flex-wrap: wrap;
//   gap: 10px;
//   justify-content: center;
//   margin-left: 3%;

// }

// .box {
//   display: flex;
//   flex-wrap: wrap;

//   background-color: #070e1b;
//   border-radius: 12px;
//   box-shadow: 0 4px 12px rgba(159, 155, 155, 0.13);
//   overflow: hidden;
//   max-width: 444px;
//   height: auto;
//   transition: transform 0.3s ease;
// }

// .box:hover {
//   transform: translateY(-10px);
// }

// .inner-container {
//   margin-left: 4%;
//   margin-right: 4%;
//   padding: 20px;
//   .img-container {
//     .case-image {
//       width: 100%;
//       height: auto;
//     }
//   }
// }

// .category-button {
//   background: #e0e0e0;
//   border: none;
//   color: #6b5858;
//   padding: 5px 10px;
//   font-weight: bold;
//   border-radius: 4px;
//   margin-top: 10px;
//   @media (max-width: 600px) {
//     font-size: 0.7rem;
//   }
// }

// .tittle {
//   color: aliceblue;
//   font-size: 1.3rem;
//   font-family: "Poppins-SemiBold,sans-serif";
//   margin-top: 0px;
//   display: flex;
//   flex-wrap: wrap;
//   letter-spacing: 0px;
//   line-height: 1.2;
//   margin-top: 10px;

//   @media (max-width: 600px) {
//     font-size: 1.1rem;
//   }
// }

// .subtitle {
//   color: #c3b7b7;
//   margin-bottom: 15px;
//   font-size: 0.8rem;
//   @media (max-width: 600px) {
//     font-size: 1rem;
//   }
// }

// .read-more {
//   background: none;
//   border: none;
//   color: #d3c6ed;
//   font-weight: bold;
//   cursor: pointer;
//   display: flex;
//   align-items: center;
//   @media (max-width: 600px) {
//     font-size: 0.7rem;
//   }
// }

// .read-more::after {
//   content: " ➜";
//   margin-left: 5px;
// }
// .flexpart {
//   display: flex;
//   justify-content: flex-start;
//   flex-wrap: wrap;
// }

// .box{
//  height: 500px;
//  background-size: cover;
//  background-position: center;
//  width: 400px;
//  @media (max-width:600px){
//   height: fit-content;
//   width: 300px;
//  }
// }
.sectionpart2 {
  display: flex;
  gap: 40px;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 100px;
  .card {
    position: relative;
    background: #1a1a1a;
    color: white;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    z-index: 0;

    border-radius: 12px;
    overflow: hidden;
    width: 320px;
    padding: 20px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    transition: transform 0.3s ease-in-out;

    &:hover {
      transform: translateY(-25px);
    }

    &__image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      opacity: 0.2;
    }

    &__content {
      position: relative;
      z-index: 10;
    }

    &__title {
      font-size: 1.4rem;
      font-weight: bold;
      margin-bottom: 12px;
      line-height: 1.3;
    }

    &__tags {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
      margin-bottom: 12px;
    }

    &__link {
      display: inline-block;
      color: #c9c7d2;
      font-weight: bold;
      text-decoration: none;
      margin-top: 10px;

      &:hover {
        text-decoration: underline;
      }
    }
    .text-color-medium-violet {
      color: var(--medium-violet-red-2);
    }
  }
  .tags {
    display: flex;
    gap: 2%;

    h5 {
      font-size: 1rem;
      color: blueviolet;
      font-family: "Poppins-Medium, sans-serif";
    }
  }
}
.card__content {
  //   display: flex;
  // justify-content: space-between;
  //   flex-direction: column;
  display: flex;
  flex-direction: column;
  gap: 80px;
}
// .cardlink{
// color: red;
// cursor: pointer;
// opacity: 1;
// &:hover{
//   color: navy;
// }

// }
