.page-banner {
  padding-top: 68px;
  padding-bottom: 40px;
  // background-image: url("https://static.vecteezy.com/system/resources/previews/025/269/246/large_2x/global-network-connection-world-map-point-and-line-composition-concept-of-global-business-illustration-vector.jpg");
  background-image: url("../../assets/images/banner.png");
  background-position: right center;
  background-size: 60%;
  background-repeat: no-repeat;
  height: 350px;
  display: flex;
  align-items: center;

  .banner-title {
    font-size: max(40px, 4vw);
    line-height: normal;
    margin-bottom: 0px;
    span {
      color: $color-text;
    }
  }

  @media (max-width: 768px) {
    height: 200px;
    background-size: 100%;
    background-position: right -200px center;
  }
}
.page-content {
  padding: 30px 0;
}

.card {
  border: 2px solid $color-border;
  border-radius: 10px;
  height: 100%;
  background-color: $color-background;
  color: $color-text;
}
.vision-mission {
  .card {
    background-image: linear-gradient(45deg, #0f1724, $color-border);
    .card-body {
      display: flex;
      gap: 24px;
      .vision,
      .mission {
        display: flex;
        flex-direction: column;
        align-content: space-between;
      }
      .our-values {
        position: relative;
        z-index: 0;
        ul {
          padding: 0;
          margin-top: 24px;
          li {
            list-style: none;
            margin-bottom: 16px;
            p,
            h6 {
              // display: inline;
              margin-right: 8px;
            }
          }
        }
      }

      &:has(.our-values) {
        position: relative;
        overflow: hidden;
        &::before {
          content: "";
          display: block;
          opacity: 0.3;
          position: absolute;
          height: 100%;
          width: 100%;
          top: 0%;
          right: 0%;
          z-index: 0;
          background-image: url("../../assets/images/banner.png");
          background-position: right -50% top;
          background-size: 60%;
          background-repeat: no-repeat;
        }
      }
      .service-title {
        text-align: right;
      }
      .description {
        font-size: 18px;
        letter-spacing: 1px;
        margin: auto;
      }
    }
  }
  .img-wrapper {
    width: 200px;
    min-width: 200px;
    height: 200px;
    border-radius: 10px;
    overflow: hidden;
    svg,
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  @media (max-width: 768px) {
    .card .card-body {
      flex-direction: column;
      gap: 16px;
      margin-top: 35px;

      .service-title {
        position: absolute;
        top: 16px;
        right: 16px;
      }
      .img-wrapper {
        height: 100px;
        width: 100px;
        min-width: 100px;
      }
    }
  }
}

// .our-commitment {
//   max-width: 700px;
//   margin: auto !important;
//   font-size: 18px;
//   letter-spacing: 1px;
//   margin: auto;
// }

.our-story {
  padding: 80px 0;
  .story-tree-wrapper {
    .list-stories {
      position: relative;
      display: flex;
      flex-direction: column;
      row-gap: 80px;
      &::before {
        content: "";
        height: 100%;
        position: absolute;
        width: 2px;
        display: block;
        background-color: $color-border;
        background-image: linear-gradient(
          to bottom,
          $color-border 90%,
          transparent
        );
        background-color: transparent;
        left: 50%;
      }
      .story-item {
        display: flex;
        align-items: center;
        gap: 24px;
        width: max(400px, 50%);
        max-width: 100%;
        margin-left: auto;
        transform: translateX(-65px);
        .thumbnail {
          height: 130px;
          width: 130px;
          min-width: 130px;
          border-radius: 50%;
          overflow: hidden;
          border: 2px solid $color-border;
          padding: 5px;
          background-color: $color-background;
          img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            border-radius: 50%;
          }

        }
        .story-content {
          p{
            text-align: justify;
          }
        }

        &:nth-child(even) {
          flex-direction: row-reverse;
          text-align: right;
          margin-right: auto;
          margin-left: 0;
          transform: translateX(65px);
        }
      }
    }
  }

  @media (max-width: 992px) {
    .story-tree-wrapper .list-stories {
      &::before {
        left: 65px;
      }
      .story-item {
        transform: translateX(0);
        align-items: flex-start;
        width: 100%;
        &:nth-child(even) {
          transform: translateX(0);
          flex-direction: row;
          text-align: left;
        }
      }
    }
  }
}

h4 {
  display: flex;
  justify-content: center;
  padding: 40px 0px;
}

.Our-team {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;

  margin: 0 auto;

  .boxpart {
    display: flex;
    justify-content: center;
    align-items: center;

    .inner-part {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-self: center;
      gap: 10px;

      height: 250px;
      width: 200px;
      .img {
        filter: drop-shadow(1px 1px 20px rgba(70, 69, 83, 0.593));
        img {
          height: 150px;
          width: 150px;
          border-radius: 50%;
        }
      }
      .teammember {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
      }
    }
  }
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
  // font-size: 25px;
}

@media screen and (max-width: 720px) {
  .greeting-text {
    h4 {
      display: flex;
      justify-content: center;
      padding: 71px 0px;
      font-size: 33px;
      line-height: 1.875rem;
      font-family: "Poppins-Bold", sans-serif;
      margin: -20px 0px;
      margin-bottom: -3.625rem;
      -webkit-font-smoothing: antialiased;
      letter-spacing: 0.035em;
      text-align: center;
      line-height: 1;
    }
    p {
      font-size: 15px;
      margin-top: 3px;
      margin-bottom: 19px;
      margin-left: 17px;
      margin-right: 1px;
    }
  }
}

@media screen and (max-width: 720px) {
  .our-story {
    h4 {
      display: flex;
      justify-content: center;
      padding: 40px 0px;
      margin: -115px 0;
    }
    .mb-5 {
      margin-bottom: 0rem !important;
    }
    .our-story .story-tree-wrapper .list-stories .story-item .thumbnail img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
    img {
      margin-top: 0%;
    }
    .our-story .story-tree-wrapper .list-stories {
      position: relative;
      display: flex;
      flex-direction: column;
      row-gap: 45px;
    }
  }
}

@media screen and (max-width: 720px) {
  h4 {
    display: flex;
    justify-content: center;
    padding: 40px 0px;
    margin: -88px 0;
  }
  .Our-team {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
    margin: 73px auto;
    .boxpart .inner-part {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-self: center;
      gap: 10px;
      height: auto;
      width: 143px;
    }
    .boxpart .inner-part .img img {
      height: auto;
      width: 93px;
      border-radius: 50%;
    }
    h6 {
      font-size: 11px;
      line-height: 1.375rem;
      font-family: "Poppins-Bold", sans-serif;
      margin: 0;
      margin-bottom: 0.25rem;
      -webkit-font-smoothing: antialiased;
      letter-spacing: 0.035em;
    }
    p {
      font-size: 7px;
      margin-top: -7px;
      margin-bottom: 0rem;
    }
  }
}

@media screen and (max-width: 720px) {
  .vision-mission {
    .mb-5 {
      margin-bottom: -3rem !important;
    }
    // h4 {
    //   display: flex;
    //   justify-content: center;
    //   padding: 55px 0px;
    //   margin: -101px 0;
    // }
    .our-values {
      h4 {
        display: flex;
        justify-content: center;
        margin: -68px 0;
        padding: 61px 0;
      }
    }
    .our-commitment {
      // padding: 72px 15px;
      // justify-content: center;
      // margin-top: -115px;
      // margin-bottom: -53px;
      h4 {
        display: flex;
        justify-content: end;
        font-size: 16px;
      }
      p {
        margin-top: 15px;
        text-align: center;
      }
    }
  }
}

/* Styles for laptops (992px to 1200px) */
// @media only screen and (max-width: 1300px) {
//   /* Your CSS styles for laptops */
//   .greeting-text {
//     h4 {
//       display: flex;
//       justify-content: center;
//       margin: 26px 0;
//       padding: 38px 0;
//     }
//     p {
//       margin-top: -40px;
//       margin-bottom: 53px;
//     }
//   }
// }

// @media only screen and (max-width: 1300px) {
//   .our-story {
//     h4 {
//       margin-top: -129px;
//     }
//     .story-tree-wrapper .list-stories {
//       position: relative;
//       display: flex;
//       flex-direction: column;
//       row-gap: 46px;
//     }
//   }
// }

@media (min-width: 1024px) {
  .greeting-text {
    h4 {
      margin-top: 25px;
      padding: 48px 0;
      display: flex;
      justify-content: center;
    }
    p {
      margin-top: -24px;
      margin-bottom: 35px;
    }
  }
}

@media (min-width: 1024px) {
  .our-story {
    h4 {
      margin-top: -115px;
    }
    .mb-5 {
      margin-bottom: 0rem !important;
    }
  }
}

@media (min-width: 1024px) {
  .Our-team {
    h4 {
      padding: 27px;
      margin-top: -59px;

      display: flex;
      justify-content: center;
    }
  }
}

@media (min-width: 1024px) {
  .vision-mission {
    h4 {
      display: flex;
      justify-content: center;
      padding: 0;
    }
  }
}

@media (min-width: 1024px) {
  .vision-mission {
    .col-md-12 {
      h4 {
        // display: flex;
        justify-content: end;
        font-size: 14px;
        margin-top: 34px;
      }
    }
  }
}
