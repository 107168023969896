section{
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 100px;
    h1{
        font-size: 55px;
        @media (max-width:600px){
            font-size: 25px;
        }
    }
.Option-part{
   display: flex;
   flex-wrap: wrap;
    gap: 20px;
    
   
  
   select{
    width: auto;
    padding: 2px;
    height: 4vh;
   outline: none;
    border-radius: 10px;
    border: none;
    color: rgb(193, 181, 181);
    background-color: #070e1b;
    box-shadow: 1px 1px 4px rgba($color: #23294b, $alpha: 1.0);
    text-align: center;
    @media (max-width:600px){
        font-size: 14px;

    }
   
   }
   
   
  
}

   
}