.detailspart {
  margin-top: 100px;
  background-color: rgba(2, 5, 44, 0.597);
  padding: 80px 0px;

  .firstwhole {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;

    .detailbox1 {
      max-width: 600px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      margin-left: 4%;
      margin-right: 4%;

      h1 {
        font-size: 43px;
        line-height: 1;
        font-family: "Poppins-SemiBold,sans-serif";
        font-weight: 600;
        // @media (max-width: 768px) {
        //   font-size: 1.3rem;
        // }
      }
      .parasubtittle {
        font-size: 16px;
        margin-top: 10%;
        // @media (max-width: 600px) {
        //   font-size: 0.9rem;
        //   margin-top: 0%;
        // }
      }
    }
  }
}

.imgpart {
  img {
    // width: 100%;

    right: 100px;
    height: 400px;
    width: auto;
  }
}
// @media (max-width: 600px) {
//   .imgpart {
//     img {
//       height: max-content;
//       width: fit-content;
//     }
//   }
// }

// .statspart {
//   display: flex;
//   flex-wrap: wrap;
//   justify-content: center;
//   align-items: center;
//   gap: 40px;
//   margin-top: -50px;
//   font-family: "Inter-Light,sans-serif";

//   padding: 10px;

//   .part1 {
//     display: flex;

//     flex-direction: column;
//     align-items: center;
//     justify-content: center;

//     h1 {
//       font-size: 30px;
//     }
//     p {
//       font-size: 20px;
//       @media (max-width: 600px) {
//         font-size: 0.7rem;
//       }
//     }
//   }
// }
// .cands {
//   display: inline;
//   margin: 0 auto;
//   text-wrap: wrap;

//   width: max(1300px);

//   h4 {
//     display: flex;
//     justify-content: center;
//     padding-bottom: 5%;
//     margin-top: 4%;
//   }

//   .challenge {
//     h4 {
//       display: flex;
//       justify-content: center;

//       margin-top: 4%;
//     }
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     flex-wrap: wrap;
//     margin-left: 20px;
//     margin-right: 20px;
//     font-family: "Inter-Light,sans-serif";
//   }
//   .solution {
//     display: flex;
//     flex-direction: column;
//     flex-wrap: wrap-reverse;
//     margin-left: 20px;
//     margin-right: 20px;
//     font-family: "Inter-Light,sans-serif";
//   }

//   @media (max-width: 600px) {
//     font-size: 0.9rem;
//   }
// }
// .form {
//   display: flex;
//   justify-content: space-around;
//   align-items: center;
//   flex-wrap: wrap;
//   margin-top: 100px;
//   background-color: rgba(2, 5, 44, 0.597);

//   label {
//     font-size: 30px;
//     text-align: center;
//     margin: 21px 66px;

//     @media (max-width: 600px) {
//       font-size: 20px;
//     }
//   }
//   .formpart {
//     input {
//       background-color: transparent;
//       border-radius: 8px;
//       border-bottom: 2px solid #52586452;

//       font-size: larger;
//       outline: none;
//       color: aliceblue;

//       @media (max-width: 600px) {
//         font-size: 10px;
//       }
//     }
//   }
//   #getintouch {
//     background-color: transparent;
//     color: aliceblue;
//     padding: 10px;
//     border-radius: 60px;
//     margin: 63px 66px;
//     &:hover {
//       color: rgba(240, 248, 255, 0.634);
//     }

//     @media (max-width: 600px) {
//       font-size: 10px;
//     }
//   }
//   .callusnow {
//     padding: 4%;
//     background-color: transparent;
//     color: aliceblue;
//     display: flex;
//     flex-direction: column;
//     justify-self: center;
//     align-items: center;

//     button {
//       background-color: transparent;
//       color: aliceblue;
//       border: none;
//       font-size: 17px;
//       padding: 6px;
//       &:hover {
//         color: rgba(240, 248, 255, 0.634);
//       }
//     }
//     p {
//       padding: 10px 0px;
//       font-size: 18px;
//       @media (max-width: 600px) {
//         font-size: 14px;
//       }
//     }

//     @media (max-width: 600px) {
//       font-size: 10px;
//     }
//   }
// }

// .feature-container {
//   display: flex;
//   justify-content: flex-start;
//   align-items: center;
//   .imgoffeature {
//     img {
//       @media (max-width: 600px) {
//         height: fit-content;
//         width: fit-content;
//         margin-right: 4%;
//       }
//     }
//   }
// }

// .img {
//   img {
//     height: 100px;
//     border-radius: 50%;
//     // width: 100px;
//   }

//   @media (max-width: 600px) {
//     padding-top: 5%;
//   }
// }
// li {
//   text-wrap: pretty;
// }

.cands {
  margin: 43px 54px;
  padding: 26px;

  h2 {
    /* width: 140px; */
    font-style: normal;
    font-family: "Times New Roman", Times, serif;
    margin-bottom: 2%;
    display: flex;
    justify-content: center;
    margin: 38px 0;
    font-size: 3rem;
    // justify-content: flex-start;
    // border: 2px solid rgb(185, 181, 181); /* Defines the border's thickness and color */
    // padding: 2px; /* Adds space between the text and the border */
    // display: inline-block;
    // border-bottom: 6px solid rgba(45, 28, 61, 0.897);
  }

  .feature {
    display: flex;
    .img {
      width: 45%;
      height: 5%;
    }
    .data1 {
      margin-top: 7%;
      justify-content: start;
    }
  }
  .challenge {
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: nowrap;
    align-items: center;
    margin-right: 12%;
    gap: 2%;

    .img {
      width: 45%;
      height: 5%;
    }
    .data2 {
      justify-content: start;
    }
  }
  .solution {
    display: flex;
    .img {
      width: 300px;
      height: 300px;
      margin-top: 20%;
    }
    .data3 {
      justify-content: start;
    }
  }
  .Result {
    display: flex;
    flex-direction: row-reverse;
    .img {
      width: 155rem;
      height: 22rem;
    }
    .data4 {
      margin-top: 8%;
      justify-content: start;
    }
  }
  .Conclusion {
    display: flex;
    .img {
      width: 155rem;
      height: 22rem;
    }
    .data5 {
      margin-top: 9%;
      justify-content: start;
    }
  }
}

@media (min-width: 320px) and (max-width: 500px) {
  .cands {
    margin: 10px 20px; /* Reduced for better fit on smaller screens */
    padding: 15px; /* Adjusted padding for mobile comfort */

    h2 {
      font-style: normal;
      font-family: "Times New Roman", Times, serif;
      margin: 10px 0; /* Reduced margin for smaller screens */
      display: flex;
      justify-content: center;
      font-size: 2rem; /* Adjusted font size for smaller screens */
      // border-bottom: 4px solid rgba(45, 28, 61, 0.897); /* Adjusted thickness */
    }

    .feature {
      display: flex;
      flex-direction: column; /* Stacked layout for mobile */
      align-items: center;

      .img {
        width: 80%; /* Reduced width for smaller screens */
        height: auto; /* Maintain aspect ratio */
      }

      .data1 {
        margin-top: 5%;
        text-align: start; /* Centered for better readability */
      }
    }

    .challenge {
      display: flex;
      flex-direction: column; /* Adjusted layout for mobile */
      align-items: center;
      margin-right: 0; /* Removed unnecessary margin */
      gap: 10px;
      // flex-direction: column-reverse;

      .img {
        width: 80%; /* Adjusted size for mobile */
        height: auto;
      }
      .data2 {
        text-align: start;
      }
    }

    .solution {
      display: flex;
      flex-direction: column; /* Stacked layout */
      align-items: center;
      // flex-direction: column-reverse;

      .img {
        width: 80%; /* Reduced size for better fit */
        height: 50%;
        margin-top: 10%; /* Adjusted for better spacing */
      }
      .data3 {
        text-align: start;
      }
    }

    .Result {
      display: flex;
      flex-direction: column;
      align-items: center;

      .img {
        width: 90%; /* Optimized for mobile screens */
        height: auto;
      }

      .data4 {
        margin-top: 5%; /* Adjusted for better spacing */
        text-align: start; /* Centered text for mobile */
      }
    }

    .Conclusion {
      display: flex;
      flex-direction: column; /* Stacked layout for mobile */
      align-items: center;

      .img {
        width: 90%; /* Optimized size */
        height: 50%;
      }

      .data5 {
        margin-top: 5%; /* Adjusted spacing */
        text-align: start; /* Improved readability */
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .cands {
    margin: 30px 40px; /* Adjusted for laptops */
    padding: 20px; /* Reduced padding for better spacing */

    h2 {
      font-style: normal;
      font-family: "Times New Roman", Times, serif;
      margin-bottom: 2%;
      display: flex;
      justify-content: center;
      margin: 30px 0; /* Adjusted margins */
      font-size: 2.5rem; /* Reduced font size for laptops */
      // border-bottom: 4px solid rgba(45, 28, 61, 0.897); /* Slightly thinner border */
    }

    .feature {
      display: flex;
      flex-wrap: wrap; /* Enables wrapping for laptop screens */
      justify-content: space-between; /* Align items evenly */
      .img {
        width: 40%; /* Adjusted image width */
        height: auto; /* Maintains aspect ratio */
      }
      .data1 {
        margin-top: 5%; /* Slightly reduced margin */
      }
    }

    .challenge {
      display: flex;
      flex-direction: row-reverse;
      flex-wrap: wrap; /* Allows wrapping for smaller laptops */
      align-items: center;
      margin-right: 10%; /* Reduced margin */
      gap: 2%; /* Spacing between elements */

      .img {
        width: 40%;
        height: auto; /* Maintains aspect ratio */
      }
    }

    .solution {
      display: flex;
      flex-direction: column; /* Stacks elements for better layout on laptops */
      align-items: center;

      .img {
        width: 250px; /* Adjusted size for laptops */
        height: auto;
        margin-top: 10%;
      }
    }

    .Result {
      display: flex;
      flex-direction: column-reverse; /* Stacks elements for better readability */
      align-items: center;

      .img {
        width: 120rem; /* Adjusted size for laptops */
        height: auto;
      }
      .data4 {
        margin-top: 5%; /* Adjusted for better spacing */
        text-align: center; /* Centers the text */
      }
    }

    .Conclusion {
      display: flex;
      flex-direction: column; /* Stacked layout */
      align-items: center;

      .img {
        width: 120rem; /* Adjusted size for laptops */
        height: auto;
      }
      .data5 {
        margin-top: 6%; /* Adjusted spacing */
        text-align: center; /* Centers the text */
      }
    }
  }
}
