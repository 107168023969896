.brand-logo-container {
  z-index: 1;
  .brand-logo {
    height: auto;
    width: 224px;
    object-fit: contain;
    position: relative;
  }
}

.main-header {
 
  height: 70px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
  border-bottom: 2px solid $color-border;

  &::before {
    height: 100%;
    width: 100%;
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    backdrop-filter: blur(10px);
  }
  .navigation {
    position: relative;

    &.mobile-navigation {
      display: none;
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }
    .navbar {
      list-style: none;
      margin-bottom: 0;
      padding: 0;
      justify-content: flex-end;
      column-gap: 30px;
      .nav-item {
        font-family: "Poppins-Light";
        .nav-link {
          padding: 23px 0px;
        }

        .sub-nav {
          position: absolute;
          padding: 4px;
          opacity: 0;
          visibility: hidden;
          transform-origin: top center;
          // transform: scaleY(0.6);
          transition: 0.3s ease;
          width: 100%;
          left: 0;
          .menu-wrapper {
            border: 2px solid $color-border;
            padding: 6px;
            min-width: 200px;
            background-color: rgba($color-background, 0.4);
            backdrop-filter: blur(10px);
            border-radius: 8px;

            ul {
              columns: 3;
              gap: 6px;
            }

            .sub-nav-link {
              color: $color-light;
              display: block;
              padding: 6px 24px;
              border-radius: 6px;
              h6 {
                font-family: $fontPrimaryMedium;
              }
              small {
                font-family: $fontBodyLight;
              }
              &:hover {
                background-color: rgba(#fff, 0.05);
              }
            }
          }
        }
        &:hover {
          .sub-nav {
            opacity: 1;
            visibility: visible;
            // transform: scaleY(1);
          }
        }
      }
    }
  }
}

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.nav-button {
  padding: 8px 22px;
  color: $color-text;
  position: relative;
  border-radius: 40px;
  border: 2px solid $color-border;
  &:hover {
    border: 2px solid $color-border;
    color: $color-light;
    background-color: $color-border;
  }
}

@media (max-width: 768px) {
  .main-header .navigation {
    &.mobile-navigation {
      display: initial;
      
    }
    &.desktop-nav {
      
      position: absolute;
      width: calc(100% - 32px);
      left: 16px;
      top: calc(100% + 16px);
      border-radius: 8px;
      overflow: hidden;
      border: 2px solid $color-border;

      transform-origin: top center;
      transform: scaleY(0.6);
      opacity: 0;
      visibility: hidden;
      transition: 0.25s ease-out;

      &.expand {
        transform: scaleY(1);
        opacity: 1;
        visibility: visible;
      }

      .navbar {
        
        flex-direction: column;
        background-image: linear-gradient(45deg, #0f1724, $color-border);
        padding: 12px 24px;

        .nav-item {
          width: 100%;
          text-align: center;
          .nav-link {
            width: 100%;
            padding: 10px 16px;
          }
          .btn {
            display: none;
            margin-top: 10px;
          }
        }
      }
    }
  }
}
