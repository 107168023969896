.main-footer {
  padding: 80px 0 0;
  position: relative;
  background-image: linear-gradient(180deg, transparent, #040911);
  .brand-logo-container {
    margin-bottom: 30px;
  }
  .privacy-statment {
    border-top: 1px solid $color-border;
  }
  ul {
    padding: 0;
    margin: 15px 0;
    li {
      list-style: none;
      margin-bottom: 12px;
    }
  }
  h6,
  a,
  p {
    color: $color-light;
    line-height: 1.8;
    cursor: pointer;
  }

  // .social-media-wrapper {

  // }
}

@media (max-width: 768px) {
  .main-footer {
    padding: 20px 0 0;
    position: absolute;
  }
  .privacy-statment {
    font-size: 12px;
    text-align: center;
  }
}

h5 {
  font-weight: 800;
  font-family: "Poppins-Medium,sans-serif";
}

.button {
  background-color: transparent;
  color: aliceblue;
  outline: none;
  background-color: #10142b;
  border: 1px solid #23294b;
  padding: 2%;
  border-radius: 10px;
  &:hover {
    background-color: #23294ba1;
  }
}

img {
  @media (max-width: 600px) {
    margin-top: 4%;
  }
}

// .container{

//   flex-wrap:wrap;
// }
// .callusnow{
//  display: flex;

//  flex-direction: column;
//  justify-self: center;
//  align-items: center;

// }
// .col-lg-4{
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   gap: 5%;
// }

// .footer-part  {
//   padding: 60px 0px ;
//   display: flex;
//   flex-wrap: wrap;
//   justify-content: flex-start;
//   margin: 0 auto;

//   justify-content: space-evenly;

//   .contact{
//     h6{
//       font-weight: 800;
//       font-family: "Poppins-Medium,sans-serif";
//     }
//   }
//   .headquater{

//     h6{
//       font-weight: 800;
//       font-family: "Poppins-Medium,sans-serif";
//     }

//   }

//   .social{
//     h6{
//       font-weight: 800;
//       font-family: "Poppins-Medium,sans-serif";
//     }

//     .icons{
//       display: flex;
//       gap: 10%;
//     }

//   }

//   .meetingbook{
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     align-items: center;
//     gap: 4%;
//     h6{
//       font-weight: 800;
//       font-family: "Poppins-Medium,sans-serif";
//     }
//     h5{
//       font-weight: 800;
//       font-family: "Poppins-Medium,sans-serif";
//     }

//     .button{
//       background-color:transparent;
//       color: aliceblue;
//       outline: none;
//       border: none;
//     }

//   }

// }

.meeting {
  @media (max-width: 600px) {
    margin-top: 4%;
  }
}
